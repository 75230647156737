import React, { useEffect, useState } from 'react';

interface Props {
  element: keyof React.ReactHTML;
  path: string;
  event?: string;
}

function LazyBackground(
  props: React.PropsWithChildren<Props & any>,
  ref: React.ForwardedRef<any>
) {
  const { element, path, children, event = 'scroll', ...rest } = props;
  const [style, setStyle] = useState<any>(rest?.style);

  useEffect(() => {
    if (event != 'none') {
      const updateStyle = () => {
        setStyle((style: any) => ({
          ...style,
          backgroundImage: `url(${path})`,
        }));
        document.removeEventListener(event, updateStyle);
      };
      document.addEventListener(event, updateStyle);

      return () => {
        document.removeEventListener(event, updateStyle);
      };
    } else {
      setStyle((style: any) => ({
        ...style,
        backgroundImage: `url(${path})`,
      }));
    }
  }, [event, path]);

  return React.createElement(element, { ...rest, ref, style }, children);
}

export default React.forwardRef(LazyBackground);
