import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player/vimeo';
// Import Swiper
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Builder } from '@builder.io/react/lite';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';

import { Colors } from '@helpers/constants';
import { swiperInit } from '@helpers/swiper';
swiperInit();
import { Breakpoints } from '@helpers/constants';
import { getVimeoCarouselData } from '@helpers/utils';
import { VimeoGalleryProps, RenderThumbnailsProps } from './VimeoGallery.types';

import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import Section from '@design/Section/Section';
import LazyBackground from '@design/LazyBackground/LazyBackground';
import BuilderImage from '@common/BuilderImage/BuilderImage';
import styles from './VimeoGallery.module.scss';

export default function VimeoGallery(props: VimeoGalleryProps) {
  const { vimeoResponse, inModal, closeModalHandler, builderBlock } = props;

  const [videoList, setVideoList] = useState(vimeoResponse);
  const [activeVideoId, setActiveVideoId] = useState(
    videoList ? videoList[0]?.video_id : null
  );

  const activeVimeoThumbnailUrl = vimeoResponse?.filter(obj => {
    return obj.video_id === activeVideoId;
  })[0]?.thumbnail_url;

  const componentId = builderBlock?.id;

  const handleSlideClick = (swiper: SwiperCore) => {
    if (swiper.clickedIndex != null) {
      setActiveVideoId(
        Number(swiper.slides[swiper.clickedIndex].getAttribute('data-video-id'))
      );
    }
  };

  useEffect(() => {
    async function setBuilderPreviewData() {
      const previewVimeoData = await getVimeoCarouselData(props.videos);
      if (previewVimeoData) {
        setVideoList(previewVimeoData);
        setActiveVideoId(previewVimeoData[0]?.video_id);
      }
    }

    Builder.isPreviewing && setBuilderPreviewData();
  }, [props.videos]);

  const videoContainerClasses = ['col'];
  if (inModal == true) {
    videoContainerClasses.push(styles.videoContainerNoBkgd);
  } else {
    videoContainerClasses.push(styles.videoContainer);
  }

  if (!activeVideoId) {
    return <BuilderEditingHelper componentName="VimeoGallery" visible={true} />;
  }

  return (
    <Section className={clsx('container', styles.videoSection)}>
      <div className="row">
        <div className={clsx(videoContainerClasses)}>
          {/* close modal */}
          {closeModalHandler && (
            <button
              className={styles.closeModal}
              aria-label="Close Modal"
              onClick={closeModalHandler}
            >
              <span>&times;</span>
            </button>
          )}

          <div className={styles.vimeoWrapper}>
            <ReactPlayer
              data-testid="vimeo-player"
              className={styles.player}
              light={activeVimeoThumbnailUrl}
              controls
              width="100%"
              height="100%"
              url={`https://player.vimeo.com/video/${activeVideoId}`}
              config={{
                playerOptions: {
                  id: activeVideoId,
                  color: Colors.primary,
                  byline: false,
                },
              }}
            />
          </div>
          {/* Slider thumbnail controls */}
          {videoList?.length > 1 && (
            <div className={styles.swiperOuterContainer}>
              <LazyBackground
                event={inModal ? 'none' : 'scroll'}
                id={`swiper-button-prev-${componentId}`}
                className={styles.swiperButtonPrev}
                element="button"
                path="images/home/arrow_right.svg"
                aria-label="arrow left"
              />
              <Swiper
                data-testid="vimeo-swiper"
                a11y={{
                  slideLabelMessage: 'Slide {{index}} of {{slidesLength}}',
                }}
                cssMode={false}
                centerInsufficientSlides={true}
                className={styles.swiper}
                spaceBetween={5}
                slidesPerView={2}
                onClick={swiper => handleSlideClick(swiper)}
                uniqueNavElements
                watchOverflow={true}
                navigation={{
                  prevEl: `#swiper-button-prev-${componentId}`,
                  nextEl: `#swiper-button-next-${componentId}`,
                }}
                breakpoints={{
                  [Breakpoints.xxs]: {
                    slidesPerView: 3,
                  },
                  [Breakpoints.lg]: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                }}
              >
                {videoList.map(item => (
                  <SwiperSlide
                    key={item.video_id}
                    data-video-id={item.video_id}
                    className={clsx(
                      styles.swiperSlide,
                      activeVideoId === item.video_id &&
                        styles.swiperSlideActive
                    )}
                  >
                    <VimeoGalleryThumbnail
                      item={item}
                      activeVideoId={activeVideoId}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <LazyBackground
                event={inModal ? 'none' : 'scroll'}
                id={`swiper-button-next-${componentId}`}
                className={styles.swiperButtonNext}
                element="button"
                path="images/home/arrow_right.svg"
                aria-label="arrow right"
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  );
}

const VimeoGalleryThumbnail = (props: RenderThumbnailsProps) => {
  const { video_id, thumbnail_url, title } = props.item;
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const vimeoThumbnail = thumbnail_url
    ? thumbnail_url
    : 'https://via.placeholder.com/153x86/94cc27/ffffff';

  useEffect(() => {
    if (anchorRef.current) {
      if (isFocused) {
        anchorRef.current.parentElement?.classList.add(
          styles.swiperSlideFocused
        );
      } else {
        anchorRef.current.parentElement?.classList.remove(
          styles.swiperSlideFocused
        );
      }
    }
  }, [isFocused]);

  return (
    <>
      <span
        className={styles.slideButton}
        ref={anchorRef}
        role="button"
        tabIndex={0}
        aria-labelledby={`slide_label_${video_id}`}
        data-video-id={video_id}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <BuilderImage
        imageSrc={vimeoThumbnail}
        alt=""
        mobileWidth={`${Breakpoints.md}px`}
        tabletWidth={`${Breakpoints.lg}px`}
        desktopWidth={`${Breakpoints.xxl}px`}
        classes={styles.vimeoThumbnails}
      />
      <span
        aria-hidden="true"
        id={`slide_label_${video_id}`}
        className={styles.vimeoTitle}
      >
        {title ? title : ''}
      </span>
    </>
  );
};
