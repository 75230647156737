import React, { useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import ReactPlayer from 'react-player/vimeo';
import Dialog from '@reach/dialog';
import '@reach/dialog/styles.css';
import DOMPurify from 'isomorphic-dompurify';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';

import { Colors } from '@helpers/constants';
import { CampaignVimeoModalProps } from './CampaignVimeoModal.types';
import { Breakpoints } from '@helpers/constants';

import Button from '@common/Button/Button';
import Section from '@design/Section/Section';
import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import BuilderImage from '@common/BuilderImage/BuilderImage';
import VimeoGallery from '@builder/VimeoGallery/VimeoGallery';
import styles from './CampaignVimeoModal.module.scss';

export default function CampaignVimeoModal(props: CampaignVimeoModalProps) {
  const {
    textBody,
    overlayImage,
    linkButton,
    modalButtonText,
    backgroundVideoId,
    backgroundThumbnailUrl,
    vimeoResponse,
    videos,
  } = props;

  const [hasEnteredViewport, setHasEnteredViewport] = useState(false);
  const container = useRef<HTMLDivElement | null>(null);
  const player = useRef<ReactPlayer | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBGVideoPlaying, setIsBGVideoPlaying] = useState(false);
  const { t } = useTranslation('common');

  const isVisible = !textBody && !overlayImage && !linkButton;

  return (
    <>
      <Section
        className={clsx(
          styles.sectionContainer,
          hasEnteredViewport ? styles.inViewport : ''
        )}
        onEnterViewport={() => {
          setIsBGVideoPlaying(true);
          setHasEnteredViewport(true);
        }}
        onExitViewport={() => {
          setIsBGVideoPlaying(false);
        }}
        data-testid="campaign-vimeo-modal"
      >
        <BuilderEditingHelper
          componentName="CampaignVimeoModal"
          visible={isVisible}
        />
        <div ref={container} className={'container'}>
          <div className="row">
            <div
              className={clsx(
                'col-12',
                'col-md-8',
                'col-lg-6',
                styles.textContainer
              )}
            >
              <div className={styles.innerContainer}>
                {/* Title SVG */}
                {overlayImage?.src && (
                  <BuilderImage
                    imageSrc={overlayImage?.src}
                    mobileWidth={`${Breakpoints.md}px`}
                    tabletWidth={`${Breakpoints.lg}px`}
                    desktopWidth={`${Breakpoints.xxl}px`}
                    alt={overlayImage?.altText}
                    classes={styles.titleImage}
                    data-testid="overlay-image"
                  />
                )}

                {/* Body Text */}
                {textBody && (
                  <div
                    className={styles.body}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(textBody),
                    }}
                    data-testid="text-body"
                  />
                )}

                <div className={styles.buttonContainer}>
                  {linkButton?.text && linkButton?.href ? (
                    <Button
                      as="a"
                      href={linkButton?.href}
                      design={linkButton?.design}
                      className={styles.submitButton}
                    >
                      {linkButton?.text}
                    </Button>
                  ) : null}

                  {modalButtonText && (
                    <Button
                      aria-label={t('Meet Sheldon - open dialog')}
                      as="button"
                      design="round"
                      className={styles.submitButton}
                      onClick={() => setIsModalOpen(true)}
                      data-testid="modal-button"
                    >
                      {modalButtonText}
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <div className={clsx('col-12', 'col-lg-6', styles.backgroundVideo)}>
              {backgroundVideoId && hasEnteredViewport && (
                <div className={styles.backgroundVideoWrapper}>
                  <BuilderImage
                    imageSrc={backgroundThumbnailUrl}
                    mobileWidth={`${Breakpoints.md}px`}
                    tabletWidth={`${Breakpoints.lg}px`}
                    desktopWidth={`${Breakpoints.xxl}px`}
                    classes={styles.facadeImage}
                  />
                  <ReactPlayer
                    muted
                    playing={isBGVideoPlaying}
                    loop
                    className={styles.player}
                    width="100%"
                    height="100%"
                    ref={player}
                    url={`https://player.vimeo.com/video/${backgroundVideoId}`}
                    data-testid="background-video"
                    config={{
                      playerOptions: {
                        backgroundVideo: true,
                        id: backgroundVideoId,
                        color: Colors.primary,
                        byline: false,
                        playsinline: true,
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Section>

      {/* Video Gallery Modal */}
      <Dialog
        aria-label={t('Meet Sheldon - video gallery')}
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        className={styles.modal}
      >
        <VimeoGallery
          vimeoResponse={vimeoResponse}
          inModal={true}
          closeModalHandler={() => setIsModalOpen(false)}
          videos={videos}
        />
      </Dialog>
    </>
  );
}
